import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const get = async () => {
  try {
    const { data: applicationConfiguration } = await axios.get('configuration')
    return applicationConfiguration
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getConfigurations = async () => {
  try {
    const { data: applicationConfiguration } = await axios.get(
      'configuration/tenant-configurations'
    )
    return applicationConfiguration
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
